/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SourceDto
 */
export interface SourceDto {
    /**
     * 
     * @type {number}
     * @memberof SourceDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SourceDto
     */
    ownerId: number;
    /**
     * 
     * @type {string}
     * @memberof SourceDto
     */
    sourceType: string;
    /**
     * 
     * @type {string}
     * @memberof SourceDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof SourceDto
     */
    filePath: string;
    /**
     * 
     * @type {string}
     * @memberof SourceDto
     */
    fileOriginName: string;
    /**
     * 
     * @type {string}
     * @memberof SourceDto
     */
    reportType: string;
    /**
     * 
     * @type {string}
     * @memberof SourceDto
     */
    status: string;
    /**
     * 
     * @type {object}
     * @memberof SourceDto
     */
    data: object;
    /**
     * 
     * @type {number}
     * @memberof SourceDto
     */
    mapperTransformId: number;
    /**
     * 
     * @type {Date}
     * @memberof SourceDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the SourceDto interface.
 */
export function instanceOfSourceDto(value: object): value is SourceDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('ownerId' in value) || value['ownerId'] === undefined) return false;
    if (!('sourceType' in value) || value['sourceType'] === undefined) return false;
    if (!('fileName' in value) || value['fileName'] === undefined) return false;
    if (!('filePath' in value) || value['filePath'] === undefined) return false;
    if (!('fileOriginName' in value) || value['fileOriginName'] === undefined) return false;
    if (!('reportType' in value) || value['reportType'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('mapperTransformId' in value) || value['mapperTransformId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function SourceDtoFromJSON(json: any): SourceDto {
    return SourceDtoFromJSONTyped(json, false);
}

export function SourceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ownerId': json['ownerId'],
        'sourceType': json['sourceType'],
        'fileName': json['fileName'],
        'filePath': json['filePath'],
        'fileOriginName': json['fileOriginName'],
        'reportType': json['reportType'],
        'status': json['status'],
        'data': json['data'],
        'mapperTransformId': json['mapperTransformId'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function SourceDtoToJSON(value?: SourceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'ownerId': value['ownerId'],
        'sourceType': value['sourceType'],
        'fileName': value['fileName'],
        'filePath': value['filePath'],
        'fileOriginName': value['fileOriginName'],
        'reportType': value['reportType'],
        'status': value['status'],
        'data': value['data'],
        'mapperTransformId': value['mapperTransformId'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

