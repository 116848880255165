import React, { useState, createRef } from "react";
import { Empty, Select, Splitter } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-components";
import { ProCard, ProTable, ActionType } from "@ant-design/pro-components";
import { useAPI } from "../../../client-api/utils/api";
import { ReportsApi } from "../../../client-api";
import { defaultFilter } from "../../../components/utils";
import { InvoiceDef } from "../../../components/invoices-status/types";
import TagInvoice from "../../../components/invoices-status/tag-invoice";
import InvoicesRecords from "../../../components/invoices-records/indes";
import ClientBillingEdit from "../../../components/client-billing-edit/indes";

import "./style.css";

type InvoicesListProps = {
  onChange: (key: string) => void;
  keyIntegrationSelected?: string;
};

const InvoicesList: React.FC<InvoicesListProps> = (props) => {
  const api = useAPI(ReportsApi);
  const { onChange, keyIntegrationSelected } = props;
  const [loading, setLoading] = useState(false);

  const actionRef: any = createRef<ActionType>();

  const status = [
    {
      value: "not_reported",
      text: InvoiceDef["not_reported"].label,
      label: InvoiceDef["not_reported"].label,
    },
    {
      value: "miss_invoices",
      text: InvoiceDef["miss_invoices"].label,
      label: InvoiceDef["miss_invoices"].label,
    },
    {
      value: "inconsistent_report",
      text: InvoiceDef["inconsistent_report"].label,
      label: InvoiceDef["inconsistent_report"].label,
    },
    {
      value: "report_completed",
      text: InvoiceDef["report_completed"].label,
      label: InvoiceDef["report_completed"].label,
    },
  ];

  const columns: ProColumns[] = [
    {
      title: <EllipsisOutlined />,
      fixed: "left",
      key: "options",
      width: 32,
      render: (_, record) => (
        <ClientBillingEdit
          filters={{ keyIntegration: record.keyIntegration }}
          iconMode
          onConfirm={() => actionRef?.current?.reload()}
        />
      ),
    },
    {
      title: "Status",
      key: "reportResult",
      dataIndex: "reportResult",
      width: 150,
      onFilter: (value, record) => true,
      filters: status,
      filterMultiple: false,
      ellipsis: true,
      renderText: (value: string) => (
        <>
          <TagInvoice value={value} />
        </>
      ),
      renderFormItem: (__, props) => <Select {...props} options={status} />,
    },
    {
      title: "NF",
      key: "nfe",
      dataIndex: "nfe",
      ellipsis: true,
      ...defaultFilter("nfe"),
    },
    {
      title: "cpf/cnpj",
      key: "cpu",
      ellipsis: true,
      dataIndex: "identifierNumber",
    },
    {
      title: "Lote",
      key: "batch",
      dataIndex: "batch",
      ellipsis: true,
      ...defaultFilter("batch"),
    },
    {
      title: "Tecnologia",
      key: "technology",
      dataIndex: "technology",
      ellipsis: true,
      ...defaultFilter("technology"),
    },
    {
      title: "Uso da semente",
      key: "seedUse",
      dataIndex: "seedUse",
      ellipsis: true,
      ...defaultFilter("seedUse"),
    },
    {
      title: "Qtd Faturamento",
      key: "disk",
      ellipsis: true,
      dataIndex: "invoicesTotalQty",
    },
    {
      title: "Qtd ITS",
      key: "royaltiesTotalQty",
      ellipsis: true,
      dataIndex: "royaltiesTotalQty",
    },
    {
      hideInTable: true,
      key: "keyIntegration",
      ellipsis: true,
      dataIndex: "keyIntegration",
    },
  ];
  return (
    <ProTable
      actionRef={actionRef}
      loading={loading}
      columns={columns}
      request={(params, sorter, filter) => {
        setLoading(true);
        const filters = { ...filter, ...params };
        delete filters.current;
        delete filters.pageSize;
        onChange("");
        return api
          .reportsControllerGetNotes({
            page: params?.current,
            pageSize: params?.pageSize,
            ...filters,
          })
          .then((data) => {
            return {
              data: data.list,
              success: true,
              total: data.total,
            };
          })
          .finally(() => setLoading(false));
      }}
      rowKey="keyIntegration"
      toolbar={{
        title: <b>Painel de consolidação de notas</b>,
      }}
      options={false}
      pagination={{
        pageSize: 12,
        showSizeChanger: false,
      }}
      scroll={{ x: 800 }}
      search={false}
      onRow={(record) => {
        return {
          onClick: () => {
            if (record.keyIntegration) {
              onChange(record.keyIntegration);
            }
          },
        };
      }}
      rowClassName={(record, index) =>
        record.keyIntegration === keyIntegrationSelected
          ? "table-row-class-key-selected"
          : ""
      }
    />
  );
};

const InvoiceRecords: React.FC = () => {
  const [keyIntegration, setKeyIntegration] = useState<string>();
  return (
    <ProCard split="vertical" style={{ marginTop: 15 }}>
      <Splitter style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
        <Splitter.Panel defaultSize="40%" min="20%" max="70%">
          <InvoicesList
            keyIntegrationSelected={keyIntegration}
            onChange={(keyIntegration) => {
              setKeyIntegration(keyIntegration);
            }}
          />
        </Splitter.Panel>
        <Splitter.Panel>
          {keyIntegration ? (
            <InvoicesRecords keyIntegration={keyIntegration} />
          ) : (
            <Empty />
          )}
        </Splitter.Panel>
      </Splitter>
    </ProCard>
  );
};

export default InvoiceRecords;
