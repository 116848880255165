import Summary from "./components/summary";
import { PageContainer } from "@ant-design/pro-components";
import InvoiceRecords from "./components/invoice-records";

const HomePage = () => {
  return (
    <PageContainer title={false}>
      <Summary />
      <InvoiceRecords />
    </PageContainer>
  );
};

export default HomePage;
