export enum InvoiceStatus {
  NOT_REPORTED = "not_reported",
}

export const InvoiceDef: { [key: string]: any } = {
  not_reported: { label: "Não reportado", color: "default" },
  report_completed: { label: "Reportado", color: "green" },
  inconsistent_report: { label: "Divergente", color: "magenta" },
  miss_invoices: { label: "Faturas não localizadas", color: "red" },
  // status auxiliares:
  bonificacao_qualidade: { label: "Bonificação Qualidade", color: "lime" },
  cultivar_experimental: { label: "Cultivar Experimental", color: "lime" },
  cultivar_sem_cadastro: { label: "Cultivar Sem Cadastro", color: "lime" },
  devolucao: { label: "Devolução", color: "lime" },
  distribuidor_sem_cadastro: {
    label: "Distribuidor Sem Cadastro",
    color: "lime",
  },
  exportacao: { label: "Exportação", color: "lime" },
  multiplicacao: { label: "Multiplicação", color: "lime" },
  nota_cancelada: { label: "Nota Cancelada", color: "lime" },
  realocado_cota: { label: "Realocado Cota", color: "lime" },
  sem_acordo: { label: "Sem Acordo", color: "lime" },
  sinistro: { label: "Sinistro", color: "lime" },
};
