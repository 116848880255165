import { initializeApp } from '@firebase/app';
import { AdditionalUserInfo, Auth, getAdditionalUserInfo, IdTokenResult, OAuthCredential, SAMLAuthProvider } from '@firebase/auth';
import {
  EmailAuthProvider,
  OAuthProvider,
} from '@firebase/auth';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
} from '@firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const authTenant = {
  tenantId: process.env.REACT_APP_OAUTH_TENANT_ID,
  oidcProviderId: process.env.REACT_APP_OIDC_PROVIDER_ID ?? '',
  samlProviderId: process.env.REACT_APP_SAML_PROVIDER_ID ?? '',
}

export const FirebaseApp = initializeApp(config);

export class Firebase {
  auth: Auth;

  emailAuthProvider: typeof EmailAuthProvider;

  oauthProvider: OAuthProvider;

  samlProvider: SAMLAuthProvider;

  constructor() {
    /* Helper */
    this.emailAuthProvider = EmailAuthProvider;

    /* Firebase APIs */
    this.auth = getAuth(FirebaseApp);

    if (authTenant.tenantId) {
      this.auth.tenantId = authTenant.tenantId;
    } else {
      throw new Error('No tenantId provided');
    }

    this.oauthProvider = new OAuthProvider(authTenant.oidcProviderId);
    this.samlProvider = new SAMLAuthProvider(authTenant.samlProviderId);

    this.oauthProvider.addScope('openid profile email');
  }

  async doSignIn(): Promise<{
    userInfo: AdditionalUserInfo | null,
    credential: OAuthCredential | null,
    refreshToken: string | null
    idToken: string
  } | undefined> {
    try {
      const result = await signInWithPopup(this.auth, this.oauthProvider);

      if (!this.auth.currentUser) {
        throw new Error('No user signed in');
      }

      const currentUser = this.auth.currentUser;

      if (!currentUser.emailVerified) {
        await this.doSendEmailVerification();
      }

      console.log({ currentUser })

      const additionalUserInfo = getAdditionalUserInfo(result)
      console.log({ additionalUserInfo })

      const credential = OAuthProvider.credentialFromResult(result);
      console.log({ credential });

      const idToken = await currentUser.getIdToken(true);
      console.log({ idToken });

      return {
        userInfo: additionalUserInfo,
        credential,
        refreshToken: result.user.refreshToken,
        idToken
      }
    } catch (error) {
      console.error(error)
      throw new Error('Error signing in with OAuth provider');
    }
  }

  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    createUserWithEmailAndPassword(this.auth, email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    signInWithEmailAndPassword(this.auth, email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email: string) => sendPasswordResetEmail(this.auth, email);

  doSendEmailVerification = async () => {
    if (this.auth && this.auth.currentUser) {
      await sendEmailVerification(this.auth.currentUser, {
        url: process.env.REACT_APP_APP_CONFIRMATION_EMAIL_REDIRECT || 'https://localhost:3000/login',
      });
    }
  };

  doPasswordUpdate = async (password: string) => {
    if (this.auth && this.auth.currentUser) {
      await updatePassword(this.auth.currentUser, password);
    }
  };

  async doGetIdToken(forceRefresh?: boolean): Promise<IdTokenResult | null> {
    return await this.auth.currentUser?.getIdTokenResult(forceRefresh) ?? null
  }

  getCurrentUser = () => this.auth.currentUser;

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next: any, fallback: any) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        next(authUser);
      } else {
        fallback();
      }
    });

  onTokenRefresh = (next: any, fallback: any) => {
    this.auth.onIdTokenChanged((authUser) => {
      if (authUser) {
        next(authUser);
      } else {
        fallback();
      }
    });
  };
}
