import { Select } from "antd";
import { InvoiceDef } from "./types";
import { SelectProps } from "antd/lib";

const SelectInvoiceStatus = (props: SelectProps) => {
  const options = Object.keys(InvoiceDef).map((key) => ({
    label: InvoiceDef[key].label,
    value: key,
  }));
  return <Select {...props} options={options} />;
};

export default SelectInvoiceStatus;
