import React, { createContext, ReactNode } from 'react';
import useSignIn from "react-auth-kit/hooks/useSignIn";
import useSignOut from 'react-auth-kit/hooks/useSignOut';

import { Firebase } from '../../libs/firebase';

interface FirebaseContextState {
  doOAuthLogin: () => Promise<boolean>;
  doLogout: () => Promise<void>;
}

const FirebaseContext = createContext<FirebaseContextState | undefined>(undefined);

interface FirebaseProviderProps {
  firebaseInstance: Firebase;
  children: ReactNode;
}

export const FirebaseProvider: React.FC<FirebaseProviderProps> = ({ firebaseInstance, children }) => {
  const signIn = useSignIn();
  const signOut = useSignOut()

  const handleOAuthLogin = async (): Promise<boolean> => {
    const userCredential = await firebaseInstance.doSignIn()
    if (userCredential?.idToken) {
      return signIn({
        auth: {
          token: userCredential?.idToken
        },
        userState: userCredential?.userInfo?.profile
      })
    }

    return false
  }

  const handleLogout = async () => {
    signOut()
    await firebaseInstance.doSignOut()
  }

  return (
    <FirebaseContext.Provider value={{
      doOAuthLogin: handleOAuthLogin,
      doLogout: handleLogout,
    }}>
      {children}
    </FirebaseContext.Provider>
  );
};

// Create a hook to use the context
export const useFirebase = (): FirebaseContextState => {
  const context = React.useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};
