/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginationResponseDto,
  SourceDto,
} from '../models/index';
import {
    PaginationResponseDtoFromJSON,
    PaginationResponseDtoToJSON,
    SourceDtoFromJSON,
    SourceDtoToJSON,
} from '../models/index';

export interface SourceControllerCreateSourceRequest {
    mapper: number;
    reporttype: SourceControllerCreateSourceReporttypeEnum;
    xOwnerId?: string;
    file?: Blob;
}

export interface SourceControllerFindAllRequest {
    reporttype: SourceControllerFindAllReporttypeEnum;
    xOwnerId?: string;
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class SourceApi extends runtime.BaseAPI {

    /**
     */
    async sourceControllerCreateSourceRaw(requestParameters: SourceControllerCreateSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SourceDto>> {
        if (requestParameters['mapper'] == null) {
            throw new runtime.RequiredError(
                'mapper',
                'Required parameter "mapper" was null or undefined when calling sourceControllerCreateSource().'
            );
        }

        if (requestParameters['reporttype'] == null) {
            throw new runtime.RequiredError(
                'reporttype',
                'Required parameter "reporttype" was null or undefined when calling sourceControllerCreateSource().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mapper'] != null) {
            queryParameters['mapper'] = requestParameters['mapper'];
        }

        if (requestParameters['reporttype'] != null) {
            queryParameters['reporttype'] = requestParameters['reporttype'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/api/v1/source/import/xls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SourceDtoFromJSON(jsonValue));
    }

    /**
     */
    async sourceControllerCreateSource(requestParameters: SourceControllerCreateSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SourceDto> {
        const response = await this.sourceControllerCreateSourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sourceControllerFindAllRaw(requestParameters: SourceControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        if (requestParameters['reporttype'] == null) {
            throw new runtime.RequiredError(
                'reporttype',
                'Required parameter "reporttype" was null or undefined when calling sourceControllerFindAll().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['reporttype'] != null) {
            queryParameters['reporttype'] = requestParameters['reporttype'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/source`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async sourceControllerFindAll(requestParameters: SourceControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.sourceControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const SourceControllerCreateSourceReporttypeEnum = {
    ClientBilling: 'client_billing',
    ClientDevolution: 'client_devolution',
    RoyaltiesNotes: 'royalties_notes',
    ItsQuotas: 'its_quotas'
} as const;
export type SourceControllerCreateSourceReporttypeEnum = typeof SourceControllerCreateSourceReporttypeEnum[keyof typeof SourceControllerCreateSourceReporttypeEnum];
/**
 * @export
 */
export const SourceControllerFindAllReporttypeEnum = {
    ClientBilling: 'client_billing',
    ClientDevolution: 'client_devolution',
    RoyaltiesNotes: 'royalties_notes',
    ItsQuotas: 'its_quotas'
} as const;
export type SourceControllerFindAllReporttypeEnum = typeof SourceControllerFindAllReporttypeEnum[keyof typeof SourceControllerFindAllReporttypeEnum];
