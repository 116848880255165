import { Tag } from "antd";
import { InvoiceDef } from "./types";

interface Props {
  value: string;
}

const TagInvoice = (props: Props) => {
  const { label, color } = InvoiceDef[props?.value] || InvoiceDef.not_reported;
  return <Tag color={color}>{label}</Tag>;
};

export default TagInvoice;
