import { StatisticCard } from "@ant-design/pro-components";
import { FileSyncOutlined } from "@ant-design/icons";
import { Button, Card, Col, Flex, Progress, Row, Typography } from "antd";
import { useAsyncRetry } from "react-use";

import { useAPI } from "../../../client-api/utils/api";
import { ReportsApi, StatusSummaryDto } from "../../../client-api";
import { useState } from "react";

const Summary = () => {
  const api = useAPI(ReportsApi);
  const [loadingDownload, setloadingDownload] = useState(false);

  const state = useAsyncRetry(async () => {
    return api.reportsControllerGetStatus().then(async (data) => {
      const main: StatusSummaryDto[] = [];
      const secondary: StatusSummaryDto[] = [];
      data.forEach((item) => {
        if (item.tryMatch) main.push(item);
        else secondary.push(item);
      });
      return {
        main,
        secondary,
      };
    });
  });

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={16} xl={19}>
        <Card
          hoverable={false}
          bordered={false}
          loading={state.loading}
          title="Registro por status"
        >
          <Flex wrap gap="middle" vertical>
            <Flex wrap gap="middle" vertical={false}>
              {state.value?.main?.map((sumarry) => {
                return (
                  <Flex align="flex-start" gap="small">
                    <StatisticCard
                      statistic={{
                        title: sumarry.name,
                        value:
                          sumarry.total !== undefined
                            ? `${sumarry.count || 0}/${sumarry.total || 0}`
                            : `${sumarry.count || 0} `,
                      }}
                      chart={
                        sumarry.total !== undefined && (
                          <Progress
                            type="dashboard"
                            status="normal"
                            percent={
                              !!sumarry?.count
                                ? Math.floor(
                                    (sumarry?.count / sumarry?.total) * 100
                                  )
                                : 0
                            }
                            size={80}
                            strokeColor={sumarry.color}
                            strokeWidth={10}
                          />
                        )
                      }
                      chartPlacement="left"
                    />
                  </Flex>
                );
              })}
            </Flex>

            <Row gutter={4}>
              {state.value?.secondary?.map((sumarry) => {
                const percent = !!sumarry?.count
                  ? (sumarry?.count / sumarry?.total) * 100
                  : 0;
                return (
                  <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <Progress
                      type="circle"
                      percent={percent}
                      strokeWidth={20}
                      strokeColor={sumarry.color}
                      size={20}
                      format={(number) => `${number}%`}
                    />
                    <span>
                      ({sumarry.count || 0}/{sumarry.total || 0}) {sumarry.name}
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Flex>
        </Card>
      </Col>

      <Col xs={24} sm={24} md={24} lg={8} xl={5}>
        <Card
          hoverable={false}
          bordered={false}
          loading={state.loading}
          title="Notas a serem reportadas"
        >
          <Typography.Paragraph>
            Existem
            <Typography.Text strong>
              {` ${
                state.value?.main.find((s) => s.slug === "not_reported")?.count
              } `}
            </Typography.Text>
            notas que ainda não foram reportadas
          </Typography.Paragraph>
          <Button
            size="large"
            block
            style={{ maxWidth: 400 }}
            type="primary"
            icon={<FileSyncOutlined />}
            loading={loadingDownload}
            onClick={async () => {
              setloadingDownload(true);
              const response = await api.reportsControllerExportITSNotes();
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(response);
              link.download = "data.xlsx"; // Nome do arquivo que será baixado
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setloadingDownload(false);
            }}
          >
            Gera aquivo
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default Summary;
