/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateResponseDto
 */
export interface UpdateResponseDto {
    /**
     * Total de itens atualizado
     * @type {number}
     * @memberof UpdateResponseDto
     */
    total: number;
    /**
     * Operação concluida com exito
     * @type {boolean}
     * @memberof UpdateResponseDto
     */
    success: boolean;
}

/**
 * Check if a given object implements the UpdateResponseDto interface.
 */
export function instanceOfUpdateResponseDto(value: object): value is UpdateResponseDto {
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('success' in value) || value['success'] === undefined) return false;
    return true;
}

export function UpdateResponseDtoFromJSON(json: any): UpdateResponseDto {
    return UpdateResponseDtoFromJSONTyped(json, false);
}

export function UpdateResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'total': json['total'],
        'success': json['success'],
    };
}

export function UpdateResponseDtoToJSON(value?: UpdateResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total': value['total'],
        'success': value['success'],
    };
}

