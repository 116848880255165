import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { BaseLayout, DashboardLayout } from "./layouts";
import MenuData from "./menu";
import LoginPage from "./pages/login";

const addRoutes = (routes: any[]) =>
  routes.map((route, index) =>
    route.routes ? (
      <Route key={index} path={route.path}>
        {addRoutes(route.routes)}
      </Route>
    ) : (
      <Route key={index} path={route.path} element={<route.component />} />
    )
  );

function App() {
  return (
    <BaseLayout>
      <Router>
        <Routes>
          <Route key={"loginpage"} path={"/login"} element={<LoginPage />} />
          <Route path="/" element={<DashboardLayout />}>
            <Route>{addRoutes(MenuData)}</Route>
          </Route>
        </Routes>
      </Router>
    </BaseLayout>
  );
}

export default App;
