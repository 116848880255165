// src/pages/SettingsPage.js

const SettingsPage = () => {
  // useEffect(() => {
  //   console.log("fazendo chamada....");
  //   const fetchUsers = async () => {
  //     try {
  //       const response = await axios.get("/users"); // Faz a requisição com o token automaticamente adicionado
  //       console.log(response.data);
  //     } catch (error) {
  //       console.error("Erro ao buscar usuários:", error);
  //     }
  //   };

  //   fetchUsers();
  // }, [axios]);

  return <div>Settings Page Content</div>;
};

export default SettingsPage;
