/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationResponseDto
 */
export interface PaginationResponseDto {
    /**
     * Total de itens
     * @type {number}
     * @memberof PaginationResponseDto
     */
    total: number;
    /**
     * Lista de itens
     * @type {Array<object>}
     * @memberof PaginationResponseDto
     */
    list: Array<object>;
}

/**
 * Check if a given object implements the PaginationResponseDto interface.
 */
export function instanceOfPaginationResponseDto(value: object): value is PaginationResponseDto {
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('list' in value) || value['list'] === undefined) return false;
    return true;
}

export function PaginationResponseDtoFromJSON(json: any): PaginationResponseDto {
    return PaginationResponseDtoFromJSONTyped(json, false);
}

export function PaginationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'total': json['total'],
        'list': json['list'],
    };
}

export function PaginationResponseDtoToJSON(value?: PaginationResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total': value['total'],
        'list': value['list'],
    };
}

