import React from "react";

import { FirebaseProvider } from "../providers/firebase";
import { Firebase } from "../libs/firebase";
import { AuthenticationProvider } from "../providers";

const firebaseInstance = new Firebase();

export const BaseLayout: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <AuthenticationProvider
      firebaseInstance={firebaseInstance}
    >
      <FirebaseProvider
        firebaseInstance={firebaseInstance}
      >
        {/* <Outlet /> */}
        {children}
      </FirebaseProvider>
    </AuthenticationProvider>
  );
};

