import React from "react";
import { WindowsOutlined } from "@ant-design/icons";
import {
  LoginForm,
  ProConfigProvider,
} from "@ant-design/pro-components";
import { theme } from "antd";
import { useNavigate } from 'react-router-dom';

import { useFirebase } from "../../providers/firebase";

const LoginPage: React.FC = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { doOAuthLogin } = useFirebase()

  return (
    <ProConfigProvider hashed={false}>
      <div style={{ backgroundColor: token.colorBgContainer }}>
        <LoginForm
          logo={process.env.PUBLIC_URL + "logo.png"}
          title="AgroRoyalties"
          subTitle="souagrosoluções"
          onFinish={async () => {
            const authenticated = await doOAuthLogin()
            if (authenticated) {
              navigate("/")
            }
          }}
          submitter={{
            searchConfig: {
              submitText: <><WindowsOutlined /> SSO Login </>,
            },
          }}
        >
        </LoginForm>
      </div>
    </ProConfigProvider>
  );
};

export default LoginPage;
