/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusSummaryDto
 */
export interface StatusSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof StatusSummaryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StatusSummaryDto
     */
    tryMatch: string;
    /**
     * 
     * @type {string}
     * @memberof StatusSummaryDto
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof StatusSummaryDto
     */
    slug: string;
    /**
     * 
     * @type {number}
     * @memberof StatusSummaryDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof StatusSummaryDto
     */
    total: number;
}

/**
 * Check if a given object implements the StatusSummaryDto interface.
 */
export function instanceOfStatusSummaryDto(value: object): value is StatusSummaryDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('tryMatch' in value) || value['tryMatch'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    return true;
}

export function StatusSummaryDtoFromJSON(json: any): StatusSummaryDto {
    return StatusSummaryDtoFromJSONTyped(json, false);
}

export function StatusSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'tryMatch': json['tryMatch'],
        'color': json['color'],
        'slug': json['slug'],
        'count': json['count'],
        'total': json['total'],
    };
}

export function StatusSummaryDtoToJSON(value?: StatusSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'tryMatch': value['tryMatch'],
        'color': value['color'],
        'slug': value['slug'],
        'count': value['count'],
        'total': value['total'],
    };
}

