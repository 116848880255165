import { Button, Input, Space } from "antd";
import { ClearOutlined, CheckOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/lib/table/interface";

export const defaultFilter = (dataIndex: string) => {
  return {
    filterDropdown: (props: FilterDropdownProps) => {
      const { setSelectedKeys, selectedKeys, confirm, clearFilters, close } =
        props;
      return (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Space.Compact block size="small">
            <Input
              placeholder={`Search`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                close();
                confirm({ closeDropdown: true });
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
            />
            <Button
              type="primary"
              size="middle"
              title="Filtrar"
              icon={<CheckOutlined />}
              onClick={() => {
                close();
                // const newValue: { [key: string]: any } = {};
                // newValue[dataIndex] = selectedKeys[0];
                // filteredInfo[dataIndex] = selectedKeys[0];
                // console.log(newValue, filteredInfo);
                // setFilteredInfo({ ...newValue });
                confirm({ closeDropdown: true });
              }}
            />
            <Button
              type="dashed"
              size="middle"
              title="Limpar filtro"
              icon={<ClearOutlined />}
              onClick={() => {
                clearFilters?.();
                confirm({ closeDropdown: true });
              }}
            />
          </Space.Compact>
        </div>
      );
    },
    onFilter: (value: any, record: any) => true,
    // filteredValue: dataIndex ? filteredInfo?.[dataIndex] : null,
  };
};
