import React, { ReactNode } from 'react';
import { Firebase } from '../../libs/firebase';
// import createRefresh from "react-auth-kit/createRefresh";
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';

// Create a provider component
interface AuthenticationProviderProps {
  firebaseInstance: Firebase;
  children: ReactNode;
}

// const authRefreshInterval = 1000 * 60 * 15 // 15 minutes

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ firebaseInstance, children }) => {
  // const refresh = createRefresh({
  //   interval: authRefreshInterval,
  //   refreshApiCallback: async (param) => {
  //     console.log('refreshApiCallback', { param })
  //     try {
  //       const token = await firebaseInstance.doGetIdToken(true)
  //       console.log('refreshApiCallback', { token })
  //       if (token?.token == null) {
  //         throw new Error('Token is null')
  //       }

  //       return {
  //         isSuccess: true,
  //         newAuthToken: token.token,
  //         newAuthTokenExpireIn: 100,
  //         newRefreshTokenExpiresIn: 600
  //       }
  //     } catch (error) {
  //       console.error(error)
  //       return {
  //         newAuthToken: '',
  //         isSuccess: false
  //       }
  //     }
  //   }
  // })

  const store = createStore({
    authName: "_auth",
    authType: "localstorage",
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === "https:",
    // refresh: refresh,
  });

  console.log({ store })

  return (
    <AuthProvider store={store}>
      {children}
    </AuthProvider>
  );
};
