import React, { useState, ReactNode } from "react";

export interface Owner {
  id: string;
  name: string;
}

interface GlobalAppState {
  getOwner: () => Owner | false;
  setOwner: (owner: Owner | false) => void;
}

const GlobalAppContext = React.createContext<GlobalAppState>(
  {} as GlobalAppState
);

type Props = {
  children?: ReactNode;
};

const GlobalAppProvider = ({ children }: Props) => {
  const [_owner, _setOwner] = useState<Owner | false>(false);

  function getOwner(): Owner | false {
    return _owner;
  }

  function setOwner(owner: Owner | false) {
    _setOwner(owner);
  }

  return (
    <GlobalAppContext.Provider
      value={{
        getOwner,
        setOwner,
      }}
    >
      {children}
    </GlobalAppContext.Provider>
  );
};

const useGlobalApp = () => {
  const context = React.useContext<GlobalAppState>(GlobalAppContext);
  if (context === undefined) {
    throw new Error("useGlobalApp must be used within a GlobalAppProvider");
  }
  return context;
};

export { GlobalAppProvider, useGlobalApp };
